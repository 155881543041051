
.navbar {
    padding: 20px;
    margin-bottom: 100px;
}

.navbar a {
    text-decoration: none;
}


.navbarMenu {
    display: none;
    flex-direction: column;
    align-items: center;
    list-style-type: none;
    padding: 50px;
}

.navbarMenu li {
    display: block;
    margin: 10px 0;
}

#visitUsButton {
    background-color: hsl(165, 56%, 33%);
    padding: 0;
    color: white;
}

.hamburger {
    display: block;
    cursor: pointer;
    font-size: 2rem;
    float: right;
    margin-top: 15px;
}

.menuIcon {
    display: none;
}

.menuIcon:checked~.navbarMenu {
    display: flex;
}


@media screen and (min-width: 600px) {


}

@media screen and (min-width: 769px) {
    
}

@media screen and (min-width: 1200px) {


    .navbarMenu {
        display: inline-block;
        position: absolute;
        top: 30px;
        right: 0;
        margin-top: 25px;
    }

    .navbarMenu li {
        display: inline-block;
        margin: 0 15px;
        font-size: 1.5rem;
    }

    .hamburger {
        display: none;
    }

}