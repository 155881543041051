.hero {
    width: 75%;
    margin: auto;
}

.elementsContainer {
    display: flex;
    justify-content: center;
}

.heroSection {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 481px) {}

@media screen and (min-width: 769px) {
    .heroSection {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }

}


@media screen and (min-width: 1025px) {

    .heroSection {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
    }

}