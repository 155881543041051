

@media screen and (min-width: 481px) {}

@media screen and (min-width: 769px) {
    .titlesContainer {
        padding-left: 100px;
    }
  

}


@media screen and (min-width: 1025px) {
    .titlesContainer {
        padding-top: 50px;
        padding-left: 200px;
    }

}