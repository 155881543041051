* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}

body {
    background-color: #E8F6EF;
    color: #1B9C85;
    font-family: 'Roboto', sans-serif;
}

a {
    color: #1B9C85;
}

h1 {
    font-size: 2em;
}

h2 {
    padding-top: 200px;
    padding-bottom: 30px;
}

h3 {
    color: #4C4C6D;
    font-size: 1em;
    padding: 4px;
}

@media screen and (min-width: 481px) {
  
}

@media screen and (min-width: 769px) {
    h1 {
        font-size: 2.5em;
    }
}


@media screen and (min-width: 1025px) {
    h1 {
        font-size: 2.8em;
    }
}

