.separatorContainer {
    text-align: center;

}

.baseSeparator {
    font-size: 8em;
    margin-right: 20px;
}

.baseSeparator:hover {
    cursor: default;
}

.additionalSeparator1 {
    color: #4C4C6D;
}

.additionalSeparator2 {
    color: #1B9C85;
}

.additionalSeparator3 {
    color: #FFE194;
}