.card {
    display: flex;
    flex-direction: column;
    border: 4px solid #7C9D96;
    border-radius: 10px;
    text-align: center;
    background-color: rgba(255, 225, 148, 0.75);
    color: #4C4C6D;
    padding: 5px;
    margin-bottom: 20px;
    font-size: 1em;
}

.flexContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img {
    width: 90%;
    height: 40%;
    border: 2px solid #7C9D96;
    margin: 10px auto;
}

.horizontalRule {
    /* display: none; */
    border: 3px dashed #4C4C6D;
    margin-top: 15px;
}






@media screen and (min-width: 600px) {
    .card {
        font-size: 1.2em;
    }

    .flexContainer {
        flex-direction: column;
    }

    .img {
        margin-bottom: 10px;
    }

}

@media screen and (min-width: 769px) {

    .card {
        font-size: 1em;

    }

    .flexContainer {
        flex-direction: row;
    }

    .img {
        margin-bottom: 15px;
    }
}


@media screen and (min-width: 1025px) {

    .card {
        font-size: 1.2em;

    }

    .img {
        margin: 15px auto;
        height: 50%;
    }

    .horizontalRule {
        display: block;
        margin-bottom: 10px;
    }

    .flexContainer {}

    .flexContainer>div {

        margin: 3px;
    }

}


@media screen and (min-width: 1200px) {
    .flexContainer {
        flex-direction: column;
    }

    .horizontalRule {
        margin-top: 30px;
    }

    .img {
        margin: 15px auto;
        height: 60%;
    }
}