.hero2 {
    width: 75%;
    margin: auto;
}

.hero2Section {
    display: grid;
    flex-direction: column;
}


@media screen and (min-width: 481px) {}

@media screen and (min-width: 769px) {
    .hero2Section {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }

}


@media screen and (min-width: 1025px) {



}
