.languageIcon {
    display: inline-block;
    margin: auto;
    margin-right: 3px;
    border-radius: 20px;
    background-color: #4C4C6D;
    padding: 5px 10px;
    font-size: 1em;
    width: auto;
    color: hsl(43, 100%, 79%);

}

@media screen and (min-width: 600px) {
    .languageIcon {
        font-size: 1.3em;
        margin-top: 4px;
    }
}

@media screen and (min-width: 769px) {
   
   
    .languageIcon {
        font-size: 0.7em;
    }
}


@media screen and (min-width: 1025px) {
    .languageIcon {
        font-size: 1.2em;
    }

}

/* @media screen and (min-width: 1200px) {
    .languageIcon {
        font-size: 2em;
    }

} */