.contactForm {
    width: 75%;
    margin: auto;
    text-align: center;
}

.inputContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.inputField {
    margin: 5px;
    border: 2px solid #1B9C85;
    background-color: rgba(255, 225, 148, 0.75);
    border-radius: 5px;
    height: 2em;
    flex: 1;
    max-width: calc(33.33% - 10px);
}

.msgField {
    margin-top: 10px;
    border: 2px solid #1B9C85;
    border-radius: 5px;
    background-color: rgba(255, 225, 148, 0.75);
    width: 100%;
}



.buttonContainer {
    text-align: center;
    margin-top: 10px;
}





@media screen and (min-width: 600px) {
    .inputContainer {
        width: 75%;
        margin: auto;
    }

}

@media screen and (min-width: 769px) {}


@media screen and (min-width: 1025px) {
    .inputContainer {
        width: 50%;
    }
}


@media screen and (min-width: 1200px) {}